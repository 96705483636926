import lazy from '../LazyComponent';

const Tasks = lazy(() => import('@/pages/TaskManagement'));
const TasksV2 = lazy(() => import('@/pages/SWSv2Menu/TaskManagement'));

const tasklistRoutes = [
  {
    path: '/tasks',
    element: Tasks,
    name: 'menu.Tasks',
    meta: {
      key: 'menu.sws.tasks',
    },
  },
  {
    path: '/tasksV2',
    element: TasksV2,
    name: 'menu.Tasks',
    meta: {
      key: 'sws.v2.tasks',
    },
  },
];

export default tasklistRoutes;
