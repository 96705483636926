import la from '../LazyComponent';

const LeadManagement = la(() => import('@/pages/LeadManagement'));
const LeadManagementV2 = la(() => import('@/pages/SWSv2Menu/LeadManagement'));
const LeadManagementRoutes = [
  {
    path: '/leads',
    element: LeadManagement,
    name: 'menu.Leads',
    meta: {
      key: 'menu.sws.lead-management',
    },
  },
  {
    path: '/leadsV2',
    element: LeadManagementV2,
    name: 'menu.Leads',
    meta: {
      key: 'sws.v2.lead-management',
    },
  },
];
export default LeadManagementRoutes;
